import React from "react"
import "./enter.scss";


export default function Enter() {
  


    return (

        <iframe title="mudits-home-page" className="iframe-main" src="/landing/landing.html">

        </iframe>

    )
}